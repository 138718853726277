import { Container } from './styled';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../context/auth';
import { LoadingContext } from '../../../../context/loading';
import { useHistory } from 'react-router';
import { IDateRange } from '../../../../entity/datetime.entity';
import theme from '../../../../theme';
import { ResponsiveRow } from '../../../../shared-styled';
import HorizontalBarChart from '../../../../components/HorizontalBarChart';
import { NumericMetrics, Input, BarChart, LineChart, FunnelChart } from '../../../../components';
import { Customer, Deal, Entry, Partner, Proposal } from '../../../../entity';
import { formatDate } from '../../../../utils/date-format';
import { currencyFormat } from '../../../../utils/string-format';
import fileDownload from 'js-file-download';
import {
  accessService,
  dealService,
  shipmentsService,
  clicksByLinkAndAccessService,
  confirmationService,
  proposalService,
  creditorErrorService,
  hourInfo,
  campaignService,
} from '../../../../service';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import { ExpandAltOutlined, DownloadOutlined } from '@ant-design/icons';
import { routeParams } from '../../../../config/routes.config';
import InfoTooltip from '../../../../components/InfoTooltip';
import { green } from '@material-ui/core/colors';
import { downloadOriginalFileUploaded } from '../../../../service/campaign.service';
import { message } from 'antd';
import { Loading } from '../../../../providers';

const ShipmentDetails = () => {
  const history: any = useHistory();

  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const [date /*, setDate*/] = useState<IDateRange>();
  const [shipment, setShipment] = useState<any>({
    shipping_date: new Date(),
  });

  const [avgNegotiationsPrice, setAvgNegotiationsPrice] = useState<number>(0);
  const [totalNegotiationsPrice, setTotalNegotiationsPrice] = useState<number>(0);
  const [firstPortionPrice, setFirstPortionPrice] = useState<number>(0);

  const [access, setAccess] = useState<number>(0);
  const [hour, setHour] = useState<Entry[]>([]);
  const [clicksByLink, setClicksByLink] = useState<any>(0);
  const [documentConfirmations, setDocumentConfirmations] = useState<number>(0);

  const { shipmentId } = history.location.state;

  const [proposals, setProposals] = useState<number>(0);
  const [negotiate, setNegotiate] = useState<number>(0);
  const [deals, setDeals] = useState<any[]>([]);

  const [dailyAccessData, setDailyAccessData] = useState<any[]>([]);
  const [dailyClicksData, setDailyClicksData] = useState<any[]>([]);

  const [creditorError, setCreditorError] = useState<any[]>([]);

  const [dailyTotalDealAmountData, setDailyTotalDealAmountData] = useState<any[]>([]);

  const [loadingAvgNegotiationsPrice, setLoadingAvgNegotiationsPrice] = useState(true);
  const [loadingTotalNegotiationsPrice, setLoadingTotalNegotiationsPrice] = useState(true);
  const [loadingFirstPortionPrice, setLoadingFirstPortionPrice] = useState(true);
  const [loadingShipment, setLoadingShipment] = useState(true);
  const [loadingAccess, setLoadingAccess] = useState(true);
  const [loadingHour, setLoadingHour] = useState(true);
  const [loadingClicksByLink, setLoadingClicksByLink] = useState(true);
  const [loadingDocumentConfirmations, setLoadingDocumentConfirmations] = useState(true);
  const [loadingProposals, setLoadingProposals] = useState(true);
  const [loadingDeals, setLoadingDeals] = useState(true);
  const [loadingCreditorError, setLoadingCreditorError] = useState(true);
  const [loadingDailyAccessData, setLoadingDailyAccessData] = useState(true);
  const [loadingDailyTotalDealAmountData, setLoadingDailyTotalDealAmountData] = useState(true);
  const [loadingShipmentAvgs, setLoadingShipmentAvgs] = useState(true);

  const [shipmentAvgs, setShipmentAvgs] = useState<any>({
    clicksByLink: 0,
    accesses: 0,
    cpc: 0,
    proposals: 0,
    negotiations: 0,
    totalNegotiationsPrice: 0,
    firstPortionPrice: 0,
    avgNegotiationsPrice: 0,
  });

  const ControllerRequisition = new AbortController();

  const fetchClicksByLink = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any = await clicksByLinkAndAccessService.listByShipment(
        partnerId,
        shipmentId,
        ControllerRequisition.signal
      );

      setClicksByLink(res.clicksByLink);
      setLoadingClicksByLink(false);
      setAccess(res.access);
      setLoadingAccess(false);
      setDocumentConfirmations(res.confirmation);
      setLoadingDocumentConfirmations(false);
      setProposals(res.proposal);
      setLoadingProposals(false);
      setNegotiate(res.negotiate);
    } catch (error: any) {
      setLoadingClicksByLink(false);
      setLoadingAccess(false);
      setLoadingDocumentConfirmations(false);
      setLoadingProposals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDailyAccessData = async (
    partnerId: number,
    shipmentId: number,
    signal?: AbortSignal
  ) => {
    try {
      const [res, res2]: any[] = await Promise.all([
        accessService.getShipmentAccessByDay(partnerId, shipmentId, signal),
        accessService.getShipmentClicksByDay(partnerId, shipmentId, signal),
      ]);

      setDailyAccessData(res);
      setDailyClicksData(res2);
      setLoadingDailyAccessData(false);
    } catch (error: any) {
      setLoadingDailyAccessData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchHour = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await hourInfo.listByShipment(partnerId, shipmentId, signal);

      setLoadingHour(false);
      setHour(res);
    } catch (error: any) {
      setLoadingHour(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDeals = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: Deal[] = await dealService.listByShipment(partnerId, shipmentId, signal);

      setDeals(res);
      setLoadingDeals(false);
    } catch (error: any) {
      setLoadingDeals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchShipmentAvgs = async (partnerId: number, signal?: AbortSignal) => {
    try {
      const res: any = await shipmentsService.getAvgsOfShipments(partnerId, signal);

      setShipmentAvgs(res);
      setLoadingShipmentAvgs(false);
    } catch (error: any) {
      setLoadingShipmentAvgs(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchCreditorErrors = async (
    partnerId: number,
    shipmentId: number,
    signal?: AbortSignal
  ) => {
    try {
      const res: any = await creditorErrorService.listCreditorErrorByShipment(
        partnerId,
        shipmentId,
        signal
      );

      setCreditorError(res);
      setLoadingCreditorError(false);
    } catch (error: any) {
      setLoadingCreditorError(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const calcDealsAvgTicket = async () => {
    const portionAmount: number[] = [];
    const amount: number[] = [];
    // eslint-disable-next-line array-callback-return
    for (let deal of deals) {
      portionAmount.push(deal.portion_amount);
      amount.push(deal.amount);
    }

    const reducer = (accumulator: number, curr: number) => accumulator + curr;

    setFirstPortionPrice(portionAmount.length > 0 ? portionAmount.reduce(reducer) : 0);

    setAvgNegotiationsPrice(
      portionAmount.length > 0 ? portionAmount.reduce(reducer) / negotiate : 0
    );

    setTotalNegotiationsPrice(amount.length > 0 ? amount.reduce(reducer) : 0);

    setLoadingFirstPortionPrice(false);
    setLoadingAvgNegotiationsPrice(false);
    setLoadingTotalNegotiationsPrice(false);
  };

  const fetchDailyNegotiationsData = async (
    partnerId: number,
    shipmentId: number,
    signal?: AbortSignal
  ) => {
    try {
      const res: any[] = await dealService.getShipmentNegotiationsByDay(
        partnerId,
        shipmentId,
        signal
      );

      setDailyTotalDealAmountData(res);
      setLoadingDailyTotalDealAmountData(false);
    } catch (error: any) {
      setLoadingDailyTotalDealAmountData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const downloadOriginalFileUploaded = async (
    campaignId: number,
    partnerId: number,
    fileName: string
  ) => {
    try {
      await campaignService.downloadOriginalFileUploaded(campaignId, partnerId, fileName);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await calcDealsAvgTicket();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deals, date]);

  useEffect(() => {
    (async () => {
      if (user && user.partner.id) {
        setLoading(false);

        await Promise.all([
          fetchClicksByLink(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchDeals(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchShipmentAvgs(user.partner.id, ControllerRequisition.signal),
          fetchHour(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchDailyAccessData(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchDailyNegotiationsData(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchCreditorErrors(user.partner.id, shipmentId, ControllerRequisition.signal),
        ]);
      }
    })();

    return () => ControllerRequisition.abort();
  }, [user, date]);

  const fetchShipment = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any = await shipmentsService.findById(partnerId, shipmentId, signal);

      if (!res.fileName) {
        const routeSplit = res.route.split('/');
        res.fileName = routeSplit[routeSplit.length - 1].replace('.txt', '');
      }

      setShipment(res);
      setLoadingShipment(false);
    } catch (error: any) {
      setLoadingShipment(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  //funcao de download do arquivo da campanha
  const downloadCampaing = async (id: any, partnerId: any, name: any, signal?: AbortSignal) => {
    try {
      await campaignService.downloadCampaign(id, partnerId, name, signal);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const shipmentTotalValue = shipment?.size * shipment?.value;

  function returnExpectation() {
    if (((firstPortionPrice / 2) * 0.15 * 100) / (shipment?.size * shipment?.value) >= 110) {
      return 'Alto';
    } else if (((firstPortionPrice / 2) * 0.15 * 100) / (shipment?.size * shipment?.value) >= 0) {
      return 'Médio';
    } else {
      return 'Baixo';
    }
  }

  function returnProblems() {
    if (creditorError.length / access >= 0.5) {
      return 'Alto: Acima de 5% dos acessos';
    } else if (creditorError.length / access >= 0.1) {
      return 'Médio: de 1% até 5% dos acessos';
    } else {
      return 'Baixo: abaixo de 1%';
    }
  }

  useEffect(() => {
    (async () => {
      if (history) {
        const { partnerId, shipmentId } = history.location.state;

        await fetchShipment(partnerId, shipmentId, ControllerRequisition.signal);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const formattedDateAccessAndClicks = (accessArray, clicksArray) => {
    let accessData = accessArray.map((t) => ({ ...t, acessos: t.values }));
    const clicksData = clicksArray.map((t) => {
      const accessItem = accessData.find((i) => t.day === i.day);
      if (accessItem) return { ...t, ...accessItem, cliques: t.values };
      return { ...t, acessos: 0, cliques: t.values };
    });

    accessData = accessData
      .filter((t) => clicksData.every((i) => i.day !== t.day))
      .map((t) => ({ ...t, acessos: t.values, cliques: 0 }));

    return [...clicksData, ...accessData];
  };

  return (
    <Container>
      <h2>
        Detalhes da campanha
        <DownloadOutlined
          onClick={() =>
            downloadOriginalFileUploaded(
              Number(shipmentId),
              Number(user.partner.id),
              shipment.fileName
            )
          }
          style={{ marginLeft: '8px', color: 'green' }}
        />
      </h2>
      <div className="shipment-details">
        <div
          className="shipment-detail-field"
          onClick={() =>
            downloadCampaing(
              shipment?.id,
              shipment?.partner,
              shipment?.name,
              ControllerRequisition.signal
            )
          }
        >
          <label>Nome da campanha:</label>
          <Input
            loading={loadingShipment}
            type="text"
            value={shipment?.name}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Data do envio:</label>
          <Input
            loading={loadingShipment}
            type="text"
            value={formatDate(new Date(shipment?.shipping_date), 'dd/MM \'às\' H:mm')}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Credor:</label>
          <Input
            loading={loadingShipment}
            type="text"
            value={shipment?.creditor_name}
            placeholder="Nome do Credor"
            disabled
          />
        </div>
      </div>

      <div className="shipment-details">
        <div className="shipment-detail-field">
          <label>Problemas com integração:</label>
          <Input
            loading={loadingCreditorError}
            type="text"
            value={returnProblems()}
            placeholder="Ex: Remessa de fim de ano"
            suffix={
              <ExpandAltOutlined
                onClick={() =>
                  history.push(routeParams.campaigns.ROUTE_CAMPAIGN_ERRORS, {
                    creditorError: creditorError,
                    campaign: history.location.state,
                  })
                }
              />
            }
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Custo do envio:</label>
          <Input
            loading={loadingShipment}
            type="text"
            value={`${currencyFormat(shipmentTotalValue)} (${shipment?.typeName})`}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Expectativa de retorno:</label>
          <Input
            loading={loadingShipment || loadingFirstPortionPrice}
            type="text"
            value={returnExpectation()}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>
      </div>

      <div className="shipment-details">
        <div className="shipment-detail-field">
          {loadingShipment && <Loading relative />}
          <label>SMS:</label>
          <TextArea rows={4} value={shipment?.message} placeholder="Texto do SMS" disabled />
        </div>
      </div>

      <div className="metrics">
        <h2>Resultado:</h2>
        <ResponsiveRow>
          <NumericMetrics
            loading={loadingClicksByLink || loadingShipmentAvgs}
            title="Cliques"
            tooltip="Quantidade total de cliques únicos em links nesta campanha"
            data={{
              period: date?.period,
              value: clicksByLink,
              compAvg: shipmentAvgs.clicksByLink,
            }}
            download={{ id: shipmentId, fileName: 'Click' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingAccess || loadingShipmentAvgs}
            title="Acessos"
            tooltip="Quantidade total de acessos únicos obtidos nesta campanha"
            data={{
              period: date?.period,
              value: access,
              comparatorValue: clicksByLink,
              compAvg: shipmentAvgs.accesses,
            }}
            download={{ id:shipmentId, fileName: 'Access' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingDocumentConfirmations || loadingShipmentAvgs}
            title="CPC"
            tooltip="Quantidade total de confirmações únicas realizadas nesta campanha"
            data={{
              period: date?.period,
              value: documentConfirmations,
              comparatorValue: access,
              compAvg: shipmentAvgs.cpc,
            }}
            download={{ id: shipmentId, fileName: 'CPC' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingProposals || loadingShipmentAvgs}
            title="Propostas"
            tooltip="Quantidade total de propostas únicas selecionadas e aceitas pelo cliente nesta campanha"
            data={{
              period: date?.period,
              value: proposals,
              comparatorValue: documentConfirmations,
              compAvg: shipmentAvgs.proposals,
            }}
            download={{ id: shipmentId, fileName: 'Proposal' }}
          ></NumericMetrics>
          
          {/*  DONWLOAD={SHIPMENTID, FILENAME } */}

          <NumericMetrics
            loading={loadingDeals || loadingShipmentAvgs}
            title="Negociações"
            tooltip="Quantidade total de negociações únicas confirmadas nesta campanha"
            data={{
              period: date?.period,
              value: negotiate,
              comparatorValue: proposals,
              compAvg: shipmentAvgs.negotiations,
            }}
            download={{ id: shipmentId, fileName: 'Negotiate'}}
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <NumericMetrics
            loading={loadingTotalNegotiationsPrice || loadingShipmentAvgs}
            title="Total negociado"
            tooltip="Total em Reais negociados nesta campanha"
            data={{
              period: date?.period,
              value: totalNegotiationsPrice,
              compAvg: shipmentAvgs.totalNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingFirstPortionPrice || loadingShipmentAvgs}
            title="1ª parcela"
            tooltip="Total em Reais para a 1ª parcela nesta campanha"
            data={{
              period: date?.period,
              value: firstPortionPrice,
              compAvg: shipmentAvgs.firstPortionPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingAvgNegotiationsPrice || loadingShipmentAvgs}
            title="Ticket médio"
            tooltip="Valor das somas da 1ª parcela dividido com a quantidade de negociações"
            data={{
              period: date?.period,
              value: avgNegotiationsPrice || 0,
              compAvg: shipmentAvgs.avgNegotiationsPrice || 0,
            }}
            showCurrency
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <NumericMetrics
            loading={loadingShipment || loadingAccess}
            title="Custo por Acesso"
            tooltip="Custo Por Acesso: Calculado a partir do valor investido na campanha dividido pelo total de acessos."
            data={{
              period: date?.period,
              value:
                shipmentTotalValue / access === Infinity
                  ? shipmentTotalValue
                  : shipmentTotalValue / access,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingShipment || loadingDocumentConfirmations}
            title="Custo por Qualificação"
            tooltip="Custo por Qualificação: Calculado a partir do valor investido na campanha dividido pelo total de confirmações de documentação."
            data={{
              period: date?.period,
              value:
                shipmentTotalValue / documentConfirmations === Infinity
                  ? shipmentTotalValue
                  : shipmentTotalValue / documentConfirmations,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingShipment || loadingDeals}
            title="Custo Por Negociação"
            tooltip="Custo Por Negociação: Calculado a partir do valor investido na campanha dividido pelo total de negociações."
            data={{
              period: date?.period,
              value:
                shipmentTotalValue / negotiate === Infinity
                  ? shipmentTotalValue
                  : shipmentTotalValue / negotiate,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingAccess || loadingDeals}
            title="Cliques Por Acordo"
            tooltip="Cliques Por Acordo: Quantidade de cliques necessários para chegar em uma negociação"
            data={{
              period: date?.period,
              value: (access / negotiate === Infinity || Number.isNaN(access / negotiate) ? 0 : access / negotiate).toFixed(2),
            }}
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <NumericMetrics
            loading={loadingDeals || loadingDocumentConfirmations}
            title="Qualificações por acordo"
            tooltip="Qualificações por acordo: Quantidade de confirmações de documentação por acordo"
            data={{
              period: date?.period,
              value: (documentConfirmations / negotiate === Infinity ||
              Number.isNaN(documentConfirmations / negotiate)
                ? 0
                : documentConfirmations / negotiate
              ).toFixed(2),
            }}
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingAccess || loadingDocumentConfirmations}
            title="Bounce Rate"
            tooltip="Taxa de rejeição da tela confirmação de cpf, quanto maior, mais pessoas acessam e não passam da primeira etapa"
            data={{
              period: date?.period,
              value: `${(Number((access - documentConfirmations) / access) * 100 < 0 ||
              Number.isNaN(((access - documentConfirmations) / access) * 100)
                ? 0
                : Number((access - documentConfirmations) / access) * 100
              ).toFixed(2)}%`,
            }}
          ></NumericMetrics>

          <NumericMetrics
            loading={loadingDeals || loadingDocumentConfirmations}
            title="Conversão por oportunidade"
            data={{
              period: date?.period,
              value: `${Number.isNaN(negotiate / documentConfirmations) ? 0 : ((negotiate / documentConfirmations) * 100).toFixed(2)}%`,
            }}
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <BarChart
            loading={loadingDailyAccessData}
            title="Acessos e cliques únicos por dia"
            data={formattedDateAccessAndClicks(dailyAccessData, dailyClicksData)}
            colors={[theme.colors.red, 'green']}
            keys={['cliques', 'acessos']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Quantidade'}
            label={'Acessos'}
          ></BarChart>

          <BarChart
            loading={loadingDailyTotalDealAmountData}
            title="Valor total dos acordos fechados por dia (1ª parcela)"
            data={dailyTotalDealAmountData.map((t) => ({ ...t, Valor: t.values }))}
            colors={[theme.colors.blue]}
            keys={['Valor']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Valor fechado'}
            prefix={'R$ '}
            formatPattern={'.2s'}
          ></BarChart>
        </ResponsiveRow>
        <ResponsiveRow>
          <FunnelChart
            loading={
              loadingAccess || loadingDocumentConfirmations || loadingProposals || loadingDeals
            }
            title="Funil de negociação"
            data={[
              { etapa: 'Cliques', values: clicksByLink, fill: '#4ace0a' },
              { etapa: 'Acessos', values: access, fill: '#faa000' },
              { etapa: 'CPC', values: documentConfirmations, fill: '#8c00ff' },
              { etapa: 'Propostas', values: proposals, fill: '#f70a0a' },
              { etapa: 'Negociações', values: negotiate, fill: '#00d9ff' },
            ]}
            colors={[theme.colors.tertiary]}
            keys={['values']}
            indexBy={'etapa'}
            axisBottomName={'Quantidade'}
            label={'Quantidade'}
          ></FunnelChart>
          <LineChart
            loading={loadingHour}
            title="Gráfico por hora"
            data={hour}
            prefix={''}
            formatPattern={'.0s'}
            suffix=""
          />
        </ResponsiveRow>
      </div>
    </Container>
  );
};

export default ShipmentDetails;
