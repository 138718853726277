import { getFileMimeType } from '../utils/get-file-mimetype';
import * as iquiteiSvc from './iquiteiApi.service';

export async function exportReport(
  partnerId: number,
  startDate: string,
  endDate: string,
  creditorId: number,
  shipmentId: number,
  queryName: string,
  fileFormat: string,
  signal?: AbortSignal
) {
  try {
    let query = '';

    if (startDate) {
      startDate = `${startDate} 00:00`;
      query = query.concat(`startDate=${startDate}&`);
    }

    if (endDate) {
      endDate = `${endDate} 23:59`;
      query = query.concat(`endDate=${endDate}&`);
    }

    if (creditorId) {
      query = query.concat(`creditorId=${creditorId}&`);
    }

    if (shipmentId) {
      query = query.concat(`shipmentId=${shipmentId}&`);
    }

    if (queryName) {
      query = query.concat(`queryName=${queryName}&`);
    }

    if (fileFormat) {
      query = query.concat(`fileFormat=${fileFormat}&`);
    }

    if (query.endsWith('&')) {
      query = query.slice(0, -1);
    }

    if (query === '') {
      throw Error('Consulta inválida.');
    }

    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/reports/export?${query}`, { signal });

    const file = res.data.data;

    const bufferData = file.bufferData.data;

    const ab = new ArrayBuffer(bufferData.length);
    const view = new Uint8Array(ab);

    for (let i = 0; i < bufferData.length; i++) {
      view[i] = bufferData[i];
    }

    const blob = new Blob([ab], { type: getFileMimeType(fileFormat) });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.fileName;
    link.dispatchEvent(new MouseEvent('click'));

    return;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao gerar o relatório';
    throw err;
  }
}

export async function listReports(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/reports`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os relatórios';
    throw err;
  }
}
