import * as iquiteiSvc from './iquiteiApi.service';

interface IListProposalsConfig {
  startDate?: string;
  endDate?: string;
  signal?: AbortSignal;
}

export async function listProposals(partnerId: number, config: IListProposalsConfig) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/proposal?start_date=${config.startDate || ''}&end_date=${config.endDate || ''}`,
      { signal: config.signal },
    );

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os CPFs confirmados';
    throw err;
  }
}

export async function listByShipment(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${shipmentId}/data/proposals`, { signal }
    );

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os CPFs confirmados';
    throw err;
  }
}

export async function listPorposalsDeny(
  partnerId: number,
  signal?: AbortSignal,
  status?: string,
  creditor?: string,
) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/proposalDeny?status=${status}&creditor=${creditor}`, { signal }
    );

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as propostas negadas';
    throw err;
  }
}

export async function updatePorposalsDeny(partnerId: number, proposalId: number, body: any) {
  try {
    const res = await iquiteiSvc.putRequest(
      `/partners/${partnerId}/v2/proposalDeny/${proposalId}`, body
    );

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao atualizar a proposta';
    throw err;
  }
}
