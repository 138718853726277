/* eslint-disable react-hooks/exhaustive-deps */

import { StepUploadFileContainer, MessageError } from './styled';
import { Input, Select } from '../../../../../../components';
import { message, Upload, Table} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../../../context/auth';
import { useContext, useEffect, useState } from 'react';
import { partnerService } from '../../../../../../service';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { LoadingContext } from '../../../../../../context/loading';
import cpfConvert from '../../../../../../utils/cpf-complete';
import { params } from '../../../../../../config';

const StepUploadFile = ({ formCampaign, setFormCampaign, onCheckIsValid }: any) => {
  const { user } = useContext(AuthContext);
  const [headersTable, setHeadersTable] = useState<any[]>([])
  const [dataTable, setDataTable] = useState<any[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { setLoading } = useContext(LoadingContext);

  const fetchPartnerCreditors = async (partnerId: number) => {
    try {
      const res: any[] = await partnerService.getPartnerCreditors(partnerId);
      setCreditors(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const dataPreview = async (infoFile: any)=>{
    let arr : any[] = []
    let headersTable : any[] = []
    infoFile.firstLine.map((item, index)=>{
      if (infoFile.headers[index] === 'CPF_CNPJ') {
        arr[infoFile.headers[index]] = cpfConvert(infoFile.firstLine[index]);
      } else {
        arr[infoFile.headers[index]] = infoFile.firstLine[index];
      }
      headersTable.push({
        title: infoFile.headers[index],
        dataIndex: infoFile.headers[index],
        key: infoFile.headers[index],
      })
      return null;
    })
    setHeadersTable(headersTable)
    setDataTable([{... arr}])

    setIsOpen(true)
  }

  const dataPreviewWithErrors = async (headers, table, errors = '')=>{
    const headersTable = headers.map((header) => ({
      title: header,
      dataIndex: header,
      key: header,
    }));
    const dataTable = table.map((item, index) => {
      let output = item;
      Object.entries(item).forEach(([key, value]) => {
        if(errors[item.linha] && errors[item.linha][key]) {
          const column: any = value;
          output[key] = <MessageError>{column} <InfoTooltip text={errors[item.linha][key]} position="top"/></MessageError>;
        }
      });
      return output;
    });
    setHeadersTable(headersTable)
    setDataTable(dataTable)

    setIsOpen(true)
  }

  useEffect(() => {
    if (user && user.partner.id) {
      fetchPartnerCreditors(user.partner.id);
    }
  }, [user]);

  const [creditors, setCreditors] = useState<any[]>([]);

  let props: any = null;

  if (user && user.partner) {
    props = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `${process.env.REACT_APP_API_URL}/partners/${user.partner.id}/v2/shipment/getHeaders`,
      onChange(info: any) {
        setLoading(true);
        const { status } = info.file;
        if (status !== 'uploading') {
          //console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          setLoading(false);
          message.success(`${info.file.name} teve sucesso ao realizar upload.`);
          setFormCampaign({ ...formCampaign, file: info.file });
          dataPreview(info.file.response.data)
        } else if (status === 'error') {
          setLoading(false);
          if (info.file.response && info.file.response.error && typeof info.file.response.error === 'string') {
            const { headers, table, infoErrors } = info.file.response;
            dataPreviewWithErrors(headers, table, infoErrors);
            message.error('O arquivo possui alguma linha com erro, verifique na tabela abaixo');
          }
          else message.error(`${info.file.name} teve falha ao realizar upload.`);
        } else if (status === 'removed') {
          setLoading(false);
        }
      },
      onDrop(e: any) {
        //console.log("Dropped files", e.dataTransfer.files);
      },
      beforeUpload(file: any) {
        // console.log(file.type);
        // let types = ['text/plain', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        // if (!types.includes(file.type)) {
        //   message.error(`${file.name} não possui formato permitido.`);
        // }
        return file.type === 'text/plain' ? true : Upload.LIST_IGNORE;
      },
      headers: {
        Authorization: localStorage.getItem(params.PARAMS_AUTH_TOKEN),
      },
    };
  }

  useEffect(() => {
    const isValidName = formCampaign.name && formCampaign.name !== '';
    const isValidCreditor = formCampaign.creditor && formCampaign.creditor !== '';

    const isValidFile = formCampaign.file !== null && formCampaign.file !== undefined;

    // eslint-disable-next-line prefer-destructuring
    const headers = formCampaign?.file?.response?.data?.headers;
    const creditorName = formCampaign?.creditor?.creditorName;

    const isValidHeaderFields =
      headers?.includes('CPF_CNPJ') && headers?.includes('Telefone') && headers?.includes('Nome');

    const isValidHeaders =
      (creditorName !== 'Return' ||
        (creditorName === 'Return' && headers?.includes('IDBinding'))) &&
      isValidHeaderFields;

    onCheckIsValid(isValidName && isValidCreditor && isValidFile && isValidHeaders);
  }, [formCampaign]);

  return (
    <StepUploadFileContainer>
      <div>
        <label>Nome da campanha:</label>
        <InfoTooltip
          text="Aqui você deve definir o nome da campanha que deseja criar, esse nome, será utilizado para encontrá-la nos relatórios."
          position="rightBottom"
        />
        <Input
          type="text"
          placeholder="Digite o nome da campanha"
          value={formCampaign.name}
          onChange={(value: any) => setFormCampaign({ ...formCampaign, name: value })}
        />
      </div>
      <div>
        <label>Selecione o credor:</label>
        <Select
          placeholder="-- Selecione --"
          firstDisabled
          optionKeys={{ value: 'name', displayName: 'creditorName' }}
          options={creditors}
          value={formCampaign?.creditor?.name ? formCampaign?.creditor?.name : ''}
          onChange={(value: any) => {
            // eslint-disable-next-line array-callback-return
            const selectedType = creditors.find((tCred: any) => {
              if (value === tCred.name) {
                return tCred;
              }
            });

            setFormCampaign({ ...formCampaign, creditor: selectedType });
          }}
        />
      </div>
      {props && (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique aqui ou arraste e solte aqui para realizar upload.
          </p>
          <p className="ant-upload-hint">
            **Apenas um arquivo por vez. Formato permitido: .csv
          </p>
        </Dragger>
      )}
      {
        isOpen && <Table dataSource={dataTable} columns={headersTable} />
      }
      

    </StepUploadFileContainer>
  );
};

export default StepUploadFile;
