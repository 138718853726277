import { Card, Container, GenerateCampaignContainer } from './styled';
import { Button } from '../../../../components';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../../context/loading';
import { Steps } from 'antd';
import { AuthContext } from '../../../../context/auth';
import StepUploadFile from './components/StepUploadFile';
import StepSetupSMSMessage from './components/StepSetupSMSMessage';
import StepSelectOperator from './components/StepSelectOperator';
import StepConfirmateCampaign from './components/StepConfirmateCampaign';
import { showToast } from '../../../../providers';
import { useHistory } from 'react-router-dom';
import { routeParams } from '../../../../config/routes.config';
import { formattedFullDate } from '../../../../utils/date-format';
import { campaignService } from '../../../../service';

const { Step } = Steps;

const steps = [
  {
    id: 0,
    title: 'Upload de arquivo',
    content: 'First-content',
  },
  {
    id: 1,
    title: 'Definir SMS',
    content: 'Second-content',
  },
  {
    id: 2,
    title: 'Configurações SMS',
    content: 'Last-content',
  },
  { 
    id: 3,
    title: 'Confirmar campanha',
    content: 'Last-content',
  },
];

const GenerateCampaign = () => {
  const history = useHistory();

  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  const [current, setCurrent] = useState<number>(0);

  const [formCampaign, setFormCampaign] = useState<any>({
    name: null,
    creditor: null,
    smsText: '',
    shippingDate: null,
    operator: {
      id: null,
      name: '',
      partnerId: '',
    },
    operatorType: {
      id: null,
      operatorsId: null,
      partnerId: null,
      typeName: '',
      name: '',
      value: '',
    },
    file: null,
    format: 1,
    phoneValidation: false,
  });

  const [validSteps, setValidSteps] = useState<any>({
    uploadFile: false,
    setupSMSMessage: false,
    selectOperator: false,
    confirmateCampaign: false,
  });

  const checkCurrentStepIsValid = () => {
    if (current === 0 && !validSteps.uploadFile) {
      if (!formCampaign.name || formCampaign.name === '') {
        showToast({
          type: 'error',
          message: 'Você precisa informar o nome da campanha',
        });
        return false;
      }

      if (!formCampaign.creditor || formCampaign.creditor === '') {
        showToast({
          type: 'error',
          message: 'Você precisa selecionar um credor para esta campanha',
        });
        return false;
      }

      if (formCampaign.file === null || formCampaign.file === undefined) {
        showToast({
          type: 'error',
          message: 'Selecione um arquivo válido para esta campanha',
        });
        return false;
      }

      // eslint-disable-next-line prefer-destructuring
      const headers: string[] = formCampaign.file.response.data.headers;

      if (!headers.includes('CPF_CNPJ')) {
        showToast({
          type: 'error',
          message:
            'O arquivo enviado deve ter obrigatoriamente ter uma coluna chamada exatamente "CPF_CNPJ"',
        });
        return false;
      }

      if (!headers.includes('Telefone')) {
        showToast({
          type: 'error',
          message:
            'O arquivo enviado deve ter obrigatoriamente ter uma coluna chamada exatamente "Telefone"',
        });
        return false;
      }

      if (!headers.includes('Nome')) {
        showToast({
          type: 'error',
          message:
            'O arquivo enviado deve ter obrigatoriamente ter uma coluna chamada exatamente "Nome"',
        });
        return false;
      }

      if (formCampaign?.creditor?.creditorName === 'Return' && !headers.includes('IDBinding')) {
        showToast({
          type: 'error',
          message:
            'Se o credor for a "Return" arquivo enviado deve ter obrigatoriamente ter uma coluna chamada "IDBinding"',
        });
        return false;
      }

      return false;
    }

    if (current === 1 && !validSteps.setupSMSMessage) {
      // @TODO add check if message contains link
      if (!formCampaign.smsText || formCampaign.smsText === '') {
        showToast({
          type: 'error',
          message: 'Você precisa informar a mensagem do SMS desta campanha',
        });
        return false;
      }
      if (formCampaign.smsText) {
        if (!formCampaign.smsText.includes('{Link}')) {
          showToast({
            type: 'error',
            message: 'Você precisa inserir o Link em algum local desta SMS',
          });
          return false;
        }
      }
      return false;
    }

    if (current === 2 && !validSteps.selectOperator) {
      // @TODO add check if message contains link
      if (!formCampaign.shippingDate || formCampaign.shippingDate === '') {
        showToast({
          type: 'error',
          message: 'Você precisa informar a data de disparo',
        });
        return false;
      }

      if (formCampaign.operator.id === null || formCampaign.operator.id === undefined) {
        showToast({
          type: 'error',
          message: 'Você precisa informar a operadora',
        });
        return false;
      }

      if (formCampaign.operatorType.id === null || formCampaign.operatorType.id === undefined) {
        showToast({
          type: 'error',
          message: 'Você precisa informar o tipo de SMS',
        });
        return false;
      }
      return false;
    }

    return true;
  };

  const next = () => {
    if (checkCurrentStepIsValid()) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleGenerateCampaign = async (form: any) => {
    try {
      setLoading(true);

      form.shippingDate = formattedFullDate(formCampaign.shippingDate);

      form.userId = user.id;
      form.partnerId = user.partner.id;

      await campaignService.generateCampaignPreventive(form);

      setLoading(false);
      showToast({
        type: 'success',
        message:
          'Sua nova campanha está em processamento. Após isso você conseguirá realizar o download.',
      });

      return history.push(routeParams.campaigns.ROUTE_CAMPAIGNS_LIST_PREVENTIVE);
    } catch (error: any) {
      setLoading(false);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      showToast({
        type: 'error',
        message: err,
      });
    }
  };

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      <h2>Gerar nova campanha preventiva</h2>

      <Card>
        <Steps className="steps" current={current}>
          {steps.map((item: any) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="divider"></div>
        <GenerateCampaignContainer>
          <div>
            {current === 0 && (
              <StepUploadFile
                formCampaign={formCampaign}
                onCheckIsValid={(value: boolean) =>
                  setValidSteps({ ...validSteps, uploadFile: value })
                }
                setFormCampaign={setFormCampaign}
              />
            )}
          </div>
          <div>
            {current === 1 && (
              <StepSetupSMSMessage
                formCampaign={formCampaign}
                onCheckIsValid={(value: boolean) =>
                  setValidSteps({ ...validSteps, setupSMSMessage: value })
                }
                setFormCampaign={setFormCampaign}
              />
            )}
          </div>
          <div>
            {current === 2 && (
              <StepSelectOperator
                formCampaign={formCampaign}
                onCheckIsValid={(value: boolean) =>
                  setValidSteps({ ...validSteps, selectOperator: value })
                }
                setFormCampaign={setFormCampaign}
              />
            )}
          </div>
          <div>
            {current === 3 && (
              <StepConfirmateCampaign
                formCampaign={formCampaign}
                onCheckIsValid={(value: boolean) =>
                  setValidSteps({ ...validSteps, confirmateCampaign: value })
                }
                setFormCampaign={setFormCampaign}
              />
            )}
          </div>

          <div className="divider"></div>

          <div className="step-actions">
            <div className="buttons">
              {current > 0 && <Button color="secondary" text="Voltar" onClick={prev} />}

              {current === steps.length - 1 ? (
                <Button
                  color="primary"
                  text="Concluir"
                  onClick={() => handleGenerateCampaign(formCampaign)}
                />
              ) : (
                <Button color="primary" text="Avançar" onClick={next} />
              )}
            </div>
          </div>
        </GenerateCampaignContainer>
      </Card>
    </Container>
  );
};

export default GenerateCampaign;
