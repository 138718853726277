import { formattedDate } from '../utils/date-format';
import * as iquiteiSvc from './iquiteiApi.service';

interface IListDealsConfig {
  startDate?: string;
  endDate?: string;
  signal?: AbortSignal;
};

interface IGetNegotiationsByDayConfig {
  startDate?: string;
  endDate?: string;
  days?: number;
  signal?: AbortSignal;
};

export async function listDeals(partnerId: number, config: IListDealsConfig) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/negotiate?start_date=${config.startDate || ''}&end_date=${config.endDate || ''}`,
      { signal: config.signal },
    );

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os CPFs confirmados';
    throw err;
  }
}

export async function getNegotiationsByDay(partnerId: number, config: IGetNegotiationsByDayConfig) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/negotiate/negotiationsByDay?start_date=${config.startDate || ''}&end_date=${config.endDate || ''}}&limit=${config.days || ''}`,
      { signal: config.signal },
    );

    return res.data.result.map((item: any) => {
      return {
        ...item,
        day: formattedDate(new Date(item.day), 'dd/MM'),
      };
    });
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as negociações por dia';
    throw err;
  }
}

export async function listByShipment(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${shipmentId}/data/negotiations`, { signal }
    );

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os CPFs confirmados';
    throw err;
  }
}

export async function getShipmentNegotiationsByDay(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${shipmentId}/data/negotiations/negotiationsByDay`, { signal }
    );

    return res.data.result.map((item: any) => {
      return {
        ...item,
        day: formattedDate(new Date(item.day), 'dd/MM'),
      };
    });
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as negociações por dia';
    throw err;
  }
}
