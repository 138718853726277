import * as iquiteiSvc from './iquiteiApi.service';

export async function listShipments(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/shipment/info/negotiation`, { signal });

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as remessas';
    throw err;
  }
}

// export async function getHeaders(partnerId: number, file: any) {
//   try {
//     const body = new FormData();

//     body.append('file', file);

//     const res = await iquiteiSvc.postMultipartRequest(
//       `/partners/${partnerId}/v2/shipment/getHeaders`,
//       body
//     );

//     return res.data.result;
//   } catch (error: any) {
//    const { data } = error.response;
//    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as remessas';
//    throw err;
//   }
// }

export async function findById(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/shipment/info/negotiation/${shipmentId}`, { signal });

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as remessas';
    throw err;
  }
}

interface IGetShipmentAvgs {
  startDate?: string;
  endDate?: string;
  signal?: AbortSignal;
}

export async function getShipmentAvgs(partnerId: number, config: IGetShipmentAvgs) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/average?start_date=${config.startDate || ''}&end_date=${config.endDate || ''}`,
      { signal: config.signal }
    );

    let data = {
      monitored: 0,
      clicksByLink: 0,
      accesses: 0,
      cpc: 0,
      proposals: 0,
      negotiations: 0,
      totalNegotiationsPrice: 0,
      firstPortionPrice: 0,
      avgNegotiationsPrice: 0,
    };

    res.data.result.forEach((item: any) => {
      return (data = {
        monitored: item.Field === 'Monitorados' ? item.Average : data.monitored,
        clicksByLink: item.Field === 'Clicks' ? item.Average : data.clicksByLink,
        accesses: item.Field === 'Acessos' ? item.Average : data.accesses,
        cpc: item.Field === 'CPC' ? item.Average : data.cpc,
        proposals: item.Field === 'Propostas' ? item.Average : data.proposals,
        negotiations: item.Field === 'Negociações' ? item.Average : data.negotiations,
        totalNegotiationsPrice:
          item.Field === 'Total Negociado' ? item.Average : data.totalNegotiationsPrice,
        firstPortionPrice: item.Field === '1 Parcela' ? item.Average : data.firstPortionPrice,
        avgNegotiationsPrice:
          item.Field === 'Ticket Medio' ? item.Average : data.avgNegotiationsPrice,
      });
    });

    return data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as remessas';
    throw err;
  }
}

export async function getAvgsOfShipments(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${partnerId}/data/average`, { signal }
    );

    let data = {
      monitored: 0,
      clicksByLink: 0,
      accesses: 0,
      cpc: 0,
      proposals: 0,
      negotiations: 0,
      totalNegotiationsPrice: 0,
      firstPortionPrice: 0,
      avgNegotiationsPrice: 0,
    };

    res.data.result.forEach((item: any) => {
      return (data = {
        monitored: item.Field === 'Monitorados' ? item.Average : data.monitored,
        clicksByLink: item.Field === 'Clicks' ? item.Average : data.clicksByLink,
        accesses: item.Field === 'Acessos' ? item.Average : data.accesses,
        cpc: item.Field === 'CPC' ? item.Average : data.cpc,
        proposals: item.Field === 'Propostas' ? item.Average : data.proposals,
        negotiations: item.Field === 'Negociações' ? item.Average : data.negotiations,
        totalNegotiationsPrice:
          item.Field === 'Total Negociado' ? item.Average : data.totalNegotiationsPrice,
        firstPortionPrice: item.Field === '1 Parcela' ? item.Average : data.firstPortionPrice,
        avgNegotiationsPrice:
          item.Field === 'Ticket Medio' ? item.Average : data.avgNegotiationsPrice,
      });
    });

    return data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as remessas';
    throw err;
  }
}

export async function createShipment(
  partnerId: number,
  file: File,
  name: string,
  message: string,
  creditorId: number,
  csvFormat: number,
  shippingDate: string
) {
  try {
    const body = new FormData();

    body.append('file', file);
    body.append('message', message);
    body.append('name', name);
    body.append('creditorId', creditorId.toString());
    body.append('format', csvFormat.toString());
    body.append('shippingDate', shippingDate);

    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/shipment`, body);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${file.name}.txt`);
    document.body.appendChild(link);
    link.click();

    return;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao gerar a campanha. Tente novamente';
    throw err;
  }
}
