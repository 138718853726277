import { useEffect, useState, useContext } from 'react';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';

import {
  getIntegrationCredentials,
  updateIntegrationCredentials
} from '../../../service/integration.service';

import { Container, ContainerChild } from './styled';
import { Loading, showToast } from '../../../providers';
import { LoadingContext } from '../../../context/loading';
import { AuthContext } from '../../../context/auth';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { Button } from '../../../components';

import { message } from 'antd';

import crypto from 'crypto';

const Integration: React.FC = () => {
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [partnerCreditorCredentials, setPartnerCreditorCredentials] = useState<any[]>([]);
  const [selectedCreditor, setSelectedCreditor] = useState<any>('');

  const fetchPartnerCreditorCredentials = async () => {
    const response: any[] = await getIntegrationCredentials(user.partner.id);

    if (response.length) {
      setPartnerCreditorCredentials(response);
    }
  };

  const validateFields = () => {
    if (!selectedCreditor.username || selectedCreditor.username === '') {
      throw showToast({
        type: 'error',
        message: 'Campo do usuário não podem estar vazio',
      });
    }
    if (!selectedCreditor.password || selectedCreditor.password === '') {
      throw showToast({
        type: 'error',
        message: 'Campo da senha não pode estar vazia',
      });
    }
  };
  
  const [encript, setEncrypt] = useState({
    initCripto: 'passwordpasswordpasswordpassword',
    securityKey: 'vectorvector1234',
    algorithm: 'aes-256-cbc'
  });

  const autoDecryptPassword = (pwd) => {
    let decipher =  crypto.createDecipheriv(`${encript.algorithm}`,
    Buffer.from(`${encript.initCripto}`), Buffer.from(`${encript.securityKey}`));
    
      let dec = decipher.update(pwd, 'hex', 'utf8')
        dec += decipher.final('utf8')

      return dec;
  }

  const autoEncryptPassword = (pwd) => {
    try {
      let cipher = crypto.createCipheriv(`${encript.algorithm}`,
      Buffer.from(`${encript.initCripto}`), Buffer.from(`${encript.securityKey}`));
      
      let crypted = cipher.update(pwd, 'utf8', 'hex');
      
      crypted += cipher.final('hex');

      return crypted;
    } catch(error) {
      console.log(error, 'error');
    }
  }
  
  const handleCreateIntegrationCredentials = async () => {
    try {
      setLoading(true);

      validateFields();

      const hashpassword = autoEncryptPassword(selectedCreditor.password);
  
      const updateData = {
        partnerId: selectedCreditor.partnerId,
        creditorId: selectedCreditor.creditorId,
        username: selectedCreditor.username,
        password: hashpassword,
      };

      await updateIntegrationCredentials(updateData);

      showToast({
        type: 'success',
        message: 'Credenciais de integração atualizadas com sucesso!',
      });

      setSelectedCreditor('')

      await fetchPartnerCreditorCredentials();

      setLoading(false);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const handleSelectCredentials = (creditorId) => {
    const creditor = partnerCreditorCredentials.find((pcc) => pcc.creditorId === creditorId);
    const decriptPwd = autoDecryptPassword(creditor.password);
    setSelectedCreditor({ ...creditor, password: decriptPwd });
  };

  useEffect(() => {
    (async () => {
      if (user) {
        await fetchPartnerCreditorCredentials();
        setLoadingContext(false);
      }
    })();
  }, [user]);

  const [showButtonSecretKey, setShowButtonSecretKey] = useState<boolean>(false);
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

  useEffect(() => {
    if(user) {
      switch (user.role) { 
        case 'ADMIN':
          setShowButtonSecretKey(true);
          break;
        case 'GERENTE':
          setShowButtonSecretKey(true);
          break;
        case 'SUPERVISOR':
          setShowButtonSecretKey(true);
          break;
        default:
          setShowButtonSecretKey(false);  
          break;
      }
    }
  },[user]);
  
  const handleShowSecretPassword = () => {
    if(user) {
      setShowSecretKey((value) => !value);

    } else {
      const err = 'Ocorreu um erro ao usar essa ferramenta';
      message.error(err);
    }
  }

  return (
    <Container>
      <h1 className="title">Configurações de integração</h1>

      <ContainerChild>
        {loading && <Loading relative />}
        <div className="selectInput">
          <label>Credor: </label>
          <Select
            placeholder="-- Selecione o credor --"
            firstDisabled
            optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
            value={selectedCreditor !== '' ? selectedCreditor.creditorId : ''}
            options={partnerCreditorCredentials}
            onChange={(value: any) => handleSelectCredentials(value)}
          />
        </div>

        {selectedCreditor && selectedCreditor !== '' && (
          <div>
            <div className="inputGroup">
              <label>Usuário de integração</label>
              <Input
                // loading={loading}
                autoComplete="off"
                type="text"
                value={selectedCreditor.username}
                onChange={(value: string) =>
                  setSelectedCreditor({ ...selectedCreditor, username: value })
                }
              />
            </div>

            <div className="inputGroup">
              <label>Senha de integração</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  autoComplete="off"
                  type={!showSecretKey ? 'password' : 'text'}
                  value={ selectedCreditor.password }
                  onChange={(value: string) =>
                    setSelectedCreditor({ ...selectedCreditor, password: value })
                  }
                  suffix={
                    showButtonSecretKey && (
                      <EyeOutlined 
                        style={{ cursor: 'pointer'}} 
                        onClick={handleShowSecretPassword} 
                      />
                    )
                  }
                />
              </div>

            </div>

            <div className="button">
              <Button
                color={'primary'}
                text={'Salvar dados'}
                icon={<CheckOutlined />}
                onClick={() => handleCreateIntegrationCredentials()}
              />
            </div>
          </div>
        )}
      </ContainerChild>
    </Container>
  );
};

export default Integration;
