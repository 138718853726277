const rootCampaignsUrl = '/campaigns';

export const routeParams = {
  ROUTE_URL: process.env.REACT_APP_URL,
  ROUTE_ROOT: '/',
  ROUTE_LOGIN: '/login',
  ROUTE_DASHBOARD: '/dashboard',
  ROUTE_FINANCE_DASHBOARD: '/finance-dashboard',
  ROUTE_USER_PROFILE: '/user-profile',
  ROUTE_FORGOT_PASSWORD: '/forgot-password',
  ROUTE_CREDITOR_CONFIGURATION: '/configuration',
  ROUTE_INTEGRATION_CONFIGURATION: '/integration-configuration',
  ROUTE_REGISTER_PARTNER: '/register-partner',
  ROUTE_REGISTER_EMPLOYEE: '/register-employee',
  ROUTE_REGISTER_CREDITOR: '/register-creditor',
  ROUTE_GENERATE_REPORTS: '/generate-reports',
  ROUTE_SYSTEM_NEWS: '/latest-news',
  ROUTE_HEALTH_CHECK: '/status-service',
  ROUTE_GENERATE_CAMPAIGN: '/generate-campaign',
  ROUTE_SYSTEM_ERRORS: '/system-errors',
  ROUTE_SYSTEM_ERROR_BY_ID: '/system-id-error',
  ROUTE_PROPOSAL_DENY: '/proposals-deny',
  ROUTE_ERROR: '/error',
  campaigns: {
    ROUTE_CAMPAIGNS_LIST_PREVENTIVE: `${rootCampaignsUrl}/preventive/list`,
    ROUTE_CAMPAIGN_DETAILS_PREVENTIVE: `${rootCampaignsUrl}/preventive/details`,
    ROUTE_CAMPAIGN_NEW_CAMPAIGN_PREVENTIVE: `${rootCampaignsUrl}/preventive/new-campaign`,
    ROUTE_CAMPAIGN_ERRORS_PREVENTIVE: `${rootCampaignsUrl}/preventive/errors`,
    ROUTE_CAMPAIGNS_LIST: `${rootCampaignsUrl}/list`,
    ROUTE_CAMPAIGN_DETAILS: `${rootCampaignsUrl}/details`,
    ROUTE_CAMPAIGN_NEW_CAMPAIGN: `${rootCampaignsUrl}/new-campaign`,
    ROUTE_CAMPAIGN_ERRORS: `${rootCampaignsUrl}/errors`,
  },
};
