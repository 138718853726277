import { useCallback, useContext, useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';

import * as dashboardFinanceSvc from '../../service/dashboardFinance';

import { AuthContext } from '../../context/auth';

import { LoadingContext } from '../../context/loading';
import { Loading } from '../../providers';

import FinanceBarChart from '../../components/FinanceBarChart';
import FinanceChart from '../../components/FinanceChart';

import Tooltip from '../../components/InfoTooltip';

import { Modal, message } from 'antd';

import { 
  Container,
  Title,
  MetricContent, 
  CardMetric, 
  ContainerRangeAssinaturePercent, 
  RangeAssinaturePercent, 
  ContentAssinature,
  AmountUsed,
  Metric,
  ContainerDash,
  Dash,
  FilterContainer,
  PlaneCard
} from './styled';

interface CardProps {
  title: string;
  value: number;
  info: string;
  active?: boolean;
}

interface MetricsDataProps{
  description: string;
  isActive: boolean;
  maxCustomersAllowed: number;
  name: string;
  surplusPrice: number;
  unitPrice: number;
  usersConsumed: number;
  amount: number;
  portionAmount: number;
  amountCall: number;
  price: number;
  amountPhones: number;
  economyPhoneValidation: number;
};

interface PlansProps {
  title: string;
  icon: string;
  active: boolean;
};

const monthsOfYear = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

export default function DashboardFinance () {

  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [load, setLoad] = useState(true);
  const [loadGraph, setLoadGraph] = useState(false);

  const ControllerRequisition = new AbortController();
  
  useEffect(() => {
    if(user) {
      setLoadingContext(false);
    }
  },[user]);

  const [card, setCard] = useState<CardProps[]>([]);

  const [amountUse, setAmountUse] = useState<number>(0);
  const [roi, setRoi] = useState<number>(0);
  const [dataMetrics, setDataMetric] = useState<MetricsDataProps>();
  const [dataGraphs, setDataGraph] = useState();
  
  const [filterMonth, setFilterMonth] = useState();

  const fetchMetricsData = async () => {
    setLoad(true);
    try {
      const data: MetricsDataProps = await dashboardFinanceSvc.getDataMetrics(
        user.partner.id, 
        ControllerRequisition.signal, 
        filterMonth && filterMonth
      );
      setDataMetric(data);
    } catch(error: any) {
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
      setModalActivePlane(err === 'Plano inativo' && true);
      
      throw error;
    } finally {
      setLoadingContext(false);
    }
    setLoad(false);

    return () => ControllerRequisition.abort();
  }

  const fetchGraphicsData = async () => {
    try {
      setLoadGraph(true);
      const data:any = await dashboardFinanceSvc.getDataGraphics(
        user.partner.id,
        ControllerRequisition.signal,
        filterMonth && filterMonth
      );
      
      setDataGraph(data);
      setLoadGraph(false);
    } catch(error) {
      throw error;
    }
  }

  useEffect(() => {
    if(user) {
      fetchMetricsData();
      fetchGraphicsData();
    }
  },[user, filterMonth]);

  const [modalActivePlane, setModalActivePlane] = useState<boolean>(false);

  useEffect(() => {
    if(dataMetrics) {
      setAmountUse((dataMetrics?.usersConsumed/dataMetrics?.maxCustomersAllowed)*100);
      setRoi(dataMetrics?.amount * dataMetrics?.unitPrice);
      setCard([
        { 
          title: 'Gasto total estimado (SMS)', 
          value: (dataMetrics?.amountCall) * (dataMetrics?.price), 
          info: 'Gasto total estimado em mensagens', 
          active: true
        },
        { 
          title: 'Est total sem validacao de tel',
          value: (dataMetrics?.amountCall) * (dataMetrics?.price),
          info: 'Estimativa total sem validação de telefone',
          active: true
        },
        { 
          title: 'Economia com validacao de tel', 
          value: dataMetrics?.economyPhoneValidation, 
          info: 'Economia com validacao de telefone', 
          active: true
        },
        { 
          title: 'Total geral negociado (1 parc)', 
          value: dataMetrics?.portionAmount || 0, 
          info: 'Total geral negociado na primeira parcela', 
          active: true
        },
        { 
          title: 'Estimativa ROI', 
          value: roi || 0, 
          info: 'Calculo feito para saber o retorno do valor de investimento por ação', 
          active: true
        },
      ]);
    }
  },[dataMetrics]);

  const [itemsPlane, setItemsPlane] = useState<PlansProps[]>([]);
  useEffect(() => {
    setItemsPlane([
      {
        title: 'CLASSIC',
        icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Star_icon_stylized.svg/1077px-Star_icon_stylized.svg.png',
        active: false,
      },
      {
        title: 'DELUXE',
        icon: 'https://cdn-icons-png.flaticon.com/512/2385/2385865.png',
        active: false,
      },
      {
        title: 'PREMIUM',
        icon: 'https://cdn-icons-png.flaticon.com/128/2055/2055833.png',
        active: false,
      },
    ]);
  },[user]);

  const handleChangeActiveItem = (i) => {
    const items = itemsPlane.map((item) => item === i 
      ? ({ ...item, active: true }) 
      : ({ ...item, active: false }) 
    );

    setItemsPlane(items);
  };
  
  const [selectFilterOptions, setSelectFilterOptions] = useState<any[]>();
  
  const fetchMonthOptions = async () => {
    try {
      setLoad(true);
      const selectOptions = await dashboardFinanceSvc.getEnableSelectData(user.partner.id);
      
      setSelectFilterOptions(selectOptions);
      setLoad(false);
    } catch(error) {
      throw error
    }
  }

  useEffect(() => {
    if(user) {
      fetchMonthOptions();
    }
  },[user]);

  const handleFetchDataFilter = useCallback((value) => {
    try {
      setFilterMonth(value);
    } catch(error) {
      throw error
    }
  },[filterMonth]);
  
  return (
    <Container>
      <Title>
        Dashboard Financeiro
      </Title>

      <Modal
        title="Ativar um plano"
        cancelText={'Fechar'}
        okText={'Ativar'}
        visible={modalActivePlane}
        onCancel={() => setModalActivePlane((value) => !value)}
        children={
          <div style={{ display: 'flex',padding: '8px', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            {itemsPlane.map((i,index) => (
              <PlaneCard 
                key={index} 
                active={i.active} 
                onClick={() => handleChangeActiveItem(i)}
              >
                <p>{i.title}</p>
                <img src={i.icon} alt='deluxe' style={{ width: '80px', height: '80px' }}/>
              </PlaneCard>
            ))}
          </div>
        }
      />
      
      <FilterContainer>
        <div className='content-text'>
          <p className='filter-name'>
            Mês
          </p>
        </div>
        <select
          value={filterMonth !== '0' ? filterMonth : '1'} 
          onChange={(event: any) => handleFetchDataFilter(event.target.value)}
        >
          {selectFilterOptions?.map((i, index) => i && (
              <option key={index} value={i.numericMonth}>
                {`${ monthsOfYear[i.numericMonth] } de ${ i.yearDate }`}
              </option>
          ))}
        </select>
      </FilterContainer>

      <div className='content-assinature'>
        <ContentAssinature>
          <ContainerRangeAssinaturePercent>
            <h4 className='title-text'>
              Seu plano: <strong>{dataMetrics?.name}</strong>
            </h4>
            
            <div className='container'>
              {load && <Loading relative />}
              <div className='input-group'>
                <p className='text'>
                  {amountUse || ''}% dos usuarios incluidos no plano
                </p>
                <RangeAssinaturePercent 
                  percent={amountUse || 0} 
                  strokeColor={{
                    '0%': '#5ab4f5',
                    '100%': '#0f29aa',
                  }}
                  showInfo={false}
                  size='default'
                />
              </div>
              <AmountUsed>
                <p className='text-amount'>
                  Total utilizado
                </p>
                <Metric>
                  <strong className='amount-us'>
                    {dataMetrics?.usersConsumed} / {dataMetrics?.maxCustomersAllowed}
                  </strong>
                </Metric>
              </AmountUsed>
            </div>
          </ContainerRangeAssinaturePercent>
        </ContentAssinature>
      </div>

      <MetricContent>
        {card?.map((i, index) => (
          <CardMetric
            className={`delay-${index}`}
            key={index} 
            background={
              index === 3 || index === 4 
              ? '#E8ECFF'
              : '#fff'
            }
            active={i.active}
          >
            {load && <Loading relative />}
            <div style={{ display: 'flex' }}>
              <p className='text-metric'>
                {i.title}:
              </p>
              <Tooltip text={i.info} />
            </div>
            <Metric>
              <strong className='percent'>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={Number(i.value)}
                prefix="R$ "
                decimalSeparator=","
                displayType="text"
                type="text"
                thousandSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
              />
              </strong>
            </Metric>
          </CardMetric>
        ))}
      </MetricContent>

      <ContainerDash>
        <Dash>
          {loadGraph && (<Loading relative />)}
          <p className='title-dash'>
            Quantidade de mensagens por dia
          </p>
          <FinanceChart data={dataGraphs} />
        </Dash>
        
        <Dash>
          {loadGraph && (<Loading relative />)}
          <p className='title-dash'>
            Quantidade de mensagens por dia (credor)
          </p>
          <FinanceBarChart data={dataGraphs} />
        </Dash>

      </ContainerDash>

    </Container>
  );
};