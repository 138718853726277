import { format } from 'd3-format';
import { Container } from './styled';
import { Loading } from '../../providers';
import { 
  BarChart as RechartsBar, 
  Bar, 
  ResponsiveContainer, 
  XAxis, 
  YAxis, 
  Tooltip, 
  CartesianGrid, 
  Label 
} from 'recharts';

interface BarChartProps {
  title: any;
  data: any[];
  colors: any[];
  keys: string[];
  indexBy: string;
  axisBottomName: string;
  axisLeftName: string;
  prefix?: string;
  suffix?: string;
  formatPattern?: string;
  label?: string;
  loading?: boolean;
  multipleItems?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  title,
  data,
  colors,
  keys,
  indexBy,
  axisBottomName,
  axisLeftName,
  prefix = '',
  suffix = '',
  formatPattern,
  loading,
  label,
}) => {
  // Example: '.3s' abbreviates thousands and milions into 2 decimal places.
  const formatValue = (value: number) => {
    if (formatPattern) {
      return `${prefix}${format(formatPattern)(value)}${suffix}`;
    }

    return `${prefix}${value}${suffix}`;
  };

  return (
    <Container>
      {loading && <Loading relative/>}
      <div className="title">{title}</div>
        <div className="content">
          <ResponsiveContainer>
            <RechartsBar
              margin={{ top: 5, right: 40, left: 40, bottom: 20 }}
              data={data}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                tickMargin={data.length <= 10 ? 5 : 15}
                dataKey={indexBy}
                tick={{ stroke: colors[1] }}
                offset={data.length <= 10 ? 32 : 45}
                angle={data.length <= 10 ? 0 : -60}
              >
                <Label value={axisBottomName} offset={-20} position="insideBottom" />
              </XAxis>
              <YAxis
                tickFormatter={(values) => formatValue(values)}
                tickCount={10}
                domain={[0, 'dataMax + 2']}
                dataKey={keys[0]}
                tick={{ stroke: colors[1]}}
              >
                <Label value={axisLeftName} position="insideLeft" offset={-15} angle={-90} />
              </YAxis>
              <Tooltip
                formatter={(value) =>
                  formatValue(value)
                }
                cursor={{ fill: 'transparent' }} 
              />
                {keys.map((item, index) => (
                <Bar
                  name={item}
                  dataKey={item}
                  fill={colors[index]}
                  radius={[5, 5, 0, 0]}
                  key={index}
                />
                ))}
          </RechartsBar>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default BarChart;
