/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { StepSetupSMSMessageContainer } from './styled';
import { Select } from '../../../../../../components';
import Checkbox from '../../../../../../components/Checkbox';
import DatePicker from '../../../../../../components/DatePicker';
import { useEffect, useState, useContext } from 'react';
import { operatorsService, operatorsTypeService } from '../../../../../../service';
import { AuthContext } from '../../../../../../context/auth';
import { message } from 'antd';
import InfoTooltip from '../../../../../../components/InfoTooltip';

const StepSetupSMSMessage = ({ formCampaign, setFormCampaign, onCheckIsValid }: any) => {
  const { user } = useContext(AuthContext);

  const [operators, setOperators] = useState<any>([
    [
      {
        id: 1,
        name: 'Operadora 1',
      },
      {
        id: 2,
        name: 'Operadora 2',
      },
    ],
  ]);

  const [type, setType] = useState<any>([
    {
      id: 1,
      operatorsId: 1,
      typeName: 'Short',
      value: 0.3,
    },
    {
      id: 2,
      operatorsId: 1,
      typeName: 'Long',
      value: 0.192,
    },
  ]);

  const getOperators = async (partnerId: number) => {
    try {
      const res: any[] = await operatorsService.getOperators(partnerId);
      setOperators(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const getTypeByOperators = async (partnerId: number, operatorId: number) => {
    try {
      const res: any[] = await operatorsTypeService.getOperatorsTypeById(partnerId, operatorId);
      setType(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
      setFormCampaign({ ...formCampaign, operator: null });
    }
  };

  const typeByOperator = () => {
    return (
      <>
        
      </>
    );
  };

  useEffect(() => {
    if (user && user.partner.id) {
      getOperators(user.partner.id);
    }
  }, [user]);

  useEffect(() => {
    if (
      user &&
      user.partner &&
      user.partner.id &&
      formCampaign.operator &&
      formCampaign.operator.id
    ) {
      getTypeByOperators(user.partner.id, formCampaign.operator.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCampaign.operator]);

  useEffect(() => {
    const isValidShippingDate = formCampaign.shippingDate && formCampaign.shippingDate !== '';
    const isValidOperator =
      formCampaign.operator &&
      formCampaign.operator.id !== null &&
      formCampaign.operator.id !== undefined;
    const isValidOperatorType =
      formCampaign.operatorType &&
      formCampaign.operatorType.id !== null &&
      formCampaign.operatorType.id !== undefined;

    onCheckIsValid(isValidShippingDate && isValidOperator && isValidOperatorType);
  }, [formCampaign]);

  return (
    <StepSetupSMSMessageContainer>
      <div>
        <div>
          <label>Data do disparo:</label>
          <InfoTooltip text="Selecione a data que deseja enviar a SMS. A data será utilizada para monitorar e gerar insigths sobre as campanhas" />

          <DatePicker
            placeholder="-- Selecione --"
            pattern="dd/MM/yyyy HH:mm"
            value={formCampaign.shippingDate}
            showTimeSelect
            onChange={(value: any) => setFormCampaign({ ...formCampaign, shippingDate: value })}
          />
        </div>
        <label>Selecione a operadora: </label>
        <Select
          placeholder="Selecione a operadora"
          firstDisabled
          optionKeys={{ value: 'id', displayName: 'name' }}
          options={operators as any[]}
          value={formCampaign.operator && formCampaign.operator.id ? formCampaign.operator.id : ''}
          onChange={(value: any) => {
            const selectedOperators = operators.find((operator: any) => {
              if (value === operator.id) {
                return operator;
              }
            });

            setFormCampaign({
              ...formCampaign,
              operator: selectedOperators,
            });
          }}
        />
        {/* {formCampaign.operator && formCampaign.operator.id && typeByOperator()} */}

        <label> Tipo de disparo </label>
        <Select
          placeholder="Selecione o tipo de disparo"
          firstDisabled
          disabled={!(formCampaign.operator && formCampaign.operator.id)}
          optionKeys={{ value: 'id', displayName: 'name' }}
          options={type as any[]}
          value={
            formCampaign.operatorType && formCampaign.operatorType.id
              ? formCampaign.operatorType.id
              : ''
          }
          onChange={
            (value: any) => {
              const selectedType = type.find((tOper: any) => {
                if (value === tOper.id) {
                  return tOper;
                }
              });

              setFormCampaign({
                ...formCampaign,
                operatorType: selectedType,
              });
            }
            // setFormCampaign({ ...formCampaign, operatorType: value })
          }
        />

        <div>
          <Checkbox
            defaultChecked={formCampaign.phoneValidation}
            onChange={(value: any) => setFormCampaign({ ...formCampaign, phoneValidation: !formCampaign.phoneValidation })}
          />
          <label>Validar telefone</label>
          <InfoTooltip text="Essa opção valida se o telefone do cliente é valido ou não" />
        </div>
      </div>
    </StepSetupSMSMessageContainer>
  );
};

export default StepSetupSMSMessage;
