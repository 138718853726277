// ### Libraries
import { useState, useEffect, useContext } from 'react';
import { EyeOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Table, message } from 'antd';

// ### Local imports
import { Button } from '../../../../components';
import { Container } from './styled';
import { formattedDate } from '../../../../utils/date-format';
import { AuthContext } from '../../../../context/auth';
import { LoadingContext } from '../../../../context/loading';
import { routeParams } from '../../../../config/routes.config';
import InfoTooltip from '../../../../components/InfoTooltip';
import { campaignService } from '../../../../service';
import { Loading } from '../../../../providers';

const ManageShipments = () => {
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const [dataSource, setDataSource] = useState<any[]>([]);

  const [loading, setLoadingBreaked] = useState(true);

  const ControllerRequisition = new AbortController();

  useEffect(() => {
    setLoading(false);
    return () => ControllerRequisition.abort();
  }, []);

  const downloadCampaing = async (campaignId: number, partnerId: number, name: string) => {
    try { 
      await campaignService.downloadCampaignPreventive(campaignId, partnerId, name, ControllerRequisition.signal);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string) => {
        return formattedDate(new Date(value));
      },
      align: 'center' as const,
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
    },
    {
      title: 'Telefones',
      dataIndex: 'phonesLength',
      key: 'phonesLength',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.phonesLength - b.phonesLength,
    },
    // {
    //   title: () => {
    //     return (
    //       <div className="header-tooltip">
    //         Monitorados
    //         <InfoTooltip
    //           text="Alguns provedores de SMS realizam um acesso prévio de prevenção de spans, esses acessos são salvos como monitorados"
    //           position="bottom"
    //         />
    //       </div>
    //     );
    //   },
    //   dataIndex: 'validatedLinksLength',
    //   key: 'validatedLinksLength',
    //   align: 'center' as const,
    //   sorter: (a: any, b: any) => a.validatedLinksLength - b.validatedLinksLength,
    // },
    {
      title: 'Clientes',
      dataIndex: 'customersLength',
      key: 'customersLength',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.customersLength - b.customersLength,
    },
    {
      title: 'Acessos',
      dataIndex: ['entriesLength', 'accessPercent'],
      key: 'entriesLength',
      align: 'center' as const,
      render: (tamanho: any, percent: any) => {
        return `${percent.entriesLength} (${percent.accessPercent})`;
      },
      sorter: (a: any, b: any) => a.entriesLength - b.entriesLength,
    },
    {
      title: () => {
        return (
          <div className="header-tooltip" style={{ display: 'flex', justifyContent:'center' }}>
            Confirmações
            <InfoTooltip
              text="O cálculo do percentual de confirmações é o resultado da divisão do número de confirmações pelo número de acessos"
              position="bottom"
            />
          </div>
        );
      },
      dataIndex: ['confirmationsLength', 'confirmationsPercent'],
      key: 'confirmationsLength',
      align: 'center' as const,
      render: (tamanho: any, percent: any) => {
        if (percent.confirmationsPercent === 'NaN%') {
          return `${percent.confirmationsLength} (0.00%)`;
        } else {
          return `${percent.confirmationsLength} (${percent.confirmationsPercent})`;
        }
      },
      sorter: (a: any, b: any) => a.confirmationsLength - b.confirmationsLength,
    },
    {
      title: () => {
        return (
          <div className="header-tooltip" style={{ display: 'flex', justifyContent:'center' }}>
            Boletos baixados
            <InfoTooltip
              text="O cálculo do percentual de boletos baixados é o resultado da divisão do número de downloads pelo número de confirmações"
              position="bottom"
            />
          </div>
        );
      },
      dataIndex: ['ticketLength', 'ticketsPercent'],
      key: 'ticketLength',
      align: 'center' as const,
      render: (tamanho: any, percent: any) => {
        if (percent.ticketsPercent === 'NaN%') {
          return `${percent.ticketLength} (0.00%)`
        } else {
          return `${percent.ticketLength} (${percent.ticketsPercent})`;
        }
      },
      sorter: (a: any, b: any) => a.ticketLength - b.ticketLength,
    },
    {
      title: 'Status',
      dataIndex: ['status', 'test'],
      key: 'status',
      render: (text: any, state: any) => {
        if (state.status === 0 || state.test === 0)
          return <div style={{ color: '#FFA500', fontWeight: 'bold' }}>Em processamento</div>;
        else if (state.status === 1 && state.test === 1)
          return <div style={{ color: '#32CD32', fontWeight: 'bold' }}>Finalizado</div>;
        else
          return <div style={{ color: '#B22222', fontWeight: 'bold' }}>Erro</div>;
      },
      sorter: (a: any, b: any) => a.status - b.status,
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: any) => (

        <>
          {record.state === 1 && (
            <EyeOutlined
              onClick={() =>
                history.push(routeParams.campaigns.ROUTE_CAMPAIGN_DETAILS_PREVENTIVE, {
                  partnerId: user?.partner.id,
                  shipmentId: record.id,
                })
              }
            />
          )}
          {record.state === 1 && (
            <DownloadOutlined
              onClick={() =>
                downloadCampaing(Number(record.id), Number(user?.partner.id), record.fileName)
              }
              style={{ marginLeft: '8px' }}
            />
          )}
        </>
      ),
    },
  ];

  const fetchShipments = async (partnerId: number) => {
    try {
      const res: any[] = await campaignService.getPreventiveCampaignsInfo(partnerId, ControllerRequisition.signal);
      setDataSource(res);
      setLoadingBreaked(false);
    } catch (error: any) {
      setLoadingBreaked(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    if (user && user.partner.id) {
      fetchShipments(user.partner.id);
    }

  }, [user]);

  return (
    <Container>
      <h2>Gerenciar campanhas de preventivo</h2>
      <div className="actions">
        <div className="btn-content">
          <Button
            color={'primary'}
            text="Nova campanha"
            icon={<PlusOutlined />}
            onClick={() => history.push(routeParams.campaigns.ROUTE_CAMPAIGN_NEW_CAMPAIGN_PREVENTIVE)}
          />
        </div>
      </div>

      <div className="shipments-list">
        {loading && <Loading relative />}
        <Table
          dataSource={[...dataSource]}
          columns={columns}
          pagination={{ defaultCurrent: 1, defaultPageSize: 10 }}
          rowKey={(item: any) => item.id}
          onChange={() => {}}
          locale={{ 
            triggerDesc: 'Clique para ordenar de forma decrescente',
            triggerAsc: 'Clique para ordenar de forma crescente', 
            cancelSort: 'Clique para cancelar a ordenação'
        }}
        />
      </div>
    </Container>
  );
};

export default ManageShipments;
