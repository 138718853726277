/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { StepSetupNegotiatePageContainer } from './styled';
import Checkbox from '../../../../../../components/Checkbox';
import { useEffect, useState, useRef } from 'react';
import NegotiationPageMockup from '../NegotiationPageMockup';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import JoditEditor from 'jodit-react';
import { negotiationsText } from '../../../../../../service';
import { Select } from '../../../../../../components';
import { Radio, Space, message } from 'antd';

const StepSetupNegotiatePage = ({ formCampaign, setFormCampaign, onCheckIsValid, user }: any) => {
  const [negotiateText, setNegotiateText] = useState<string>(formCampaign.negotiateText);

  const [campaignVariableNames] = useState<string[]>(formCampaign.file.response.data.headers);

  const [textNegotiations, setTextNegotiations] = useState<string[]>();
  
  const [selectedText, setSelectedText]=useState<string[]>();

  
  const concatVariable = (variable: string) => {
    if (negotiateText?.includes(variable)) {
      return;
    }
    setNegotiateText(negotiateText?.concat(`{${variable}}`));

    const elem: any = document.getElementById('sms-text-input');

    if (elem) {
      elem.focus();
    }
  };

  const editor = useRef(null);

  useEffect(() => {
    setFormCampaign({ ...formCampaign, negotiateText: negotiateText });
    // eslint-disable-next-line
  }, [negotiateText]);

  const getNegotiationsText = async (partnerId: number) => {
    try {
      const res: any[] = await negotiationsText.getNegotiationsText(partnerId);
      

      setTextNegotiations(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(()=>{
    getNegotiationsText(user.partner.id)
  },[user])

  useEffect(() => {
    const isValidnegotiateText = formCampaign.negotiateText && formCampaign.negotiateText !== '';
    const isWhatsappContact =
      !formCampaign.whatsapp ||
      (formCampaign.whatsapp &&
        formCampaign.whatsappNumber !== null &&
        formCampaign.whatsappNumber !== undefined &&
        formCampaign.whatsappNumber !== '');

    onCheckIsValid(isValidnegotiateText && isWhatsappContact);
  }, [formCampaign]);

  return (
    <StepSetupNegotiatePageContainer>
      <div className="columns column-form">
        <div>
          <label>Variáveis disponíveis:</label>
          <InfoTooltip text=" As variáveis são buscadas a partir do nome das colunas do arquivo. Você pode utilizá-las na confecção do texto da página de negociação e observar o resultado no simulador ao lado" />
          <div className="variables-list">
            {campaignVariableNames.map((variable: string, idx) => {
              if (!negotiateText?.includes(variable)) {
                return (
                  <div key={idx} className="variable-item" onClick={() => concatVariable(variable)}>
                    {variable}
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div>
          <label> Textos salvos:</label>
          <Select
           placeholder="Selecione o texto de negociação"
           firstDisabled
           optionKeys={{ value: 'id', displayName: 'name' }}
           options={textNegotiations as any[]}
           value={selectedText}
           onChange={(value:any)=>{
             textNegotiations?.find((text: any) =>{
               if(value === text.id){
                 setNegotiateText(text.text)
                 return text.text;
               }
             })
             setSelectedText(value)
           }}

          />
          <label>Texto da página:</label>
       
          <JoditEditor
            ref={editor}
            value={negotiateText}
            //config={config}
            //tabIndex={1} // tabIndex of textarea
            //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(value: string) => {
              setNegotiateText(value);
            }}
          />
        </div>
        <div className="options-box">
          <label>Configurações: </label>
          {/* <div>
            <Checkbox
              defaultChecked={formCampaign.whatsapp}
              onChange={(value: any) =>
                setFormCampaign({
                  ...formCampaign,
                  whatsapp: value,
                  whatsappNumber: null,
                })
              }
            />
            <label>Whatsapp</label>
            <InfoTooltip text="Ao marcar essa opção você permitirá que o cliente acesse o botão WHATSAPP na página de negociação e caso clique, seja direcionado para o número cadastrado" />
            {formCampaign.whatsapp && (
              <Input
                type="text"
                placeholder="Número"
                value={formCampaign.whatsappNumber}
                onChange={(value: string) =>
                  setFormCampaign({ ...formCampaign, whatsappNumber: value })
                }
              />
            )}
          </div> */}
          <div>
            <Checkbox
              defaultChecked={formCampaign.call}
              onChange={(value: any) => setFormCampaign({ ...formCampaign, call: value })}
            />
            <label>Central de atendimento</label>
            <InfoTooltip text="Essa opção indica o telefone da central de atendimento da assessoria" />
          </div>

          <label>Formato: </label>

          <Radio.Group
            onChange={(value: any) => setFormCampaign({ ...formCampaign, format: value.target.value })}
            value={formCampaign.format}
          >
            <Space direction="vertical">
              <Radio value={0}>
                Formato Recovery
                <InfoTooltip text="Esse formato retornará um arquivo no padrão: Telefone;IdTelefone;IdCliente;CodigoCampanha;Codigo_do_Produto;Nome;IdMensagem;DtDisparo;HoraDisparoInicio;HoraDisparoFinal..." />
              </Radio>
              <Radio value={1}>
                Formato SMS
                <InfoTooltip text="Esse formato retornará um arquivo no padrão: Telefone;Mensagem;Cpf" />
              </Radio>
            </Space>
          </Radio.Group>

          {/* <div>

            <Checkbox
              defaultChecked={formCampaign.format}
              onChange={(value: any) => setFormCampaign({ ...formCampaign, format: 0 })}
            />
            <label>Formato Recovery 2 </label>
            <InfoTooltip text="Esse formato retornará um arquivo no padrão: Telefone;IdTelefone;IdCliente;CodigoCampanha;Codigo_do_Produto;Nome;IdMensagem;DtDisparo;HoraDisparoInicio;HoraDisparoFinal..." />
          </div>

          <div>
            <Checkbox
              defaultChecked={formCampaign.format}
              onChange={(value: any) => setFormCampaign({ ...formCampaign, format: 1 })}
            />
            <label>Formato SMS</label>
            <InfoTooltip text="Esse formato retornará um arquivo no padrão: Telefone;Mensagem;Cpf" />
          </div> */}
        </div>
      </div>
      <div className="columns column-phone">
        <NegotiationPageMockup formCampaign={formCampaign} setFormCampaign={setFormCampaign} />
      </div>
    </StepSetupNegotiatePageContainer>
  );
};

export default StepSetupNegotiatePage;
