import * as iquiteiSvc from './iquiteiApi.service';

export async function getDataMetrics (partnerId, signal?: AbortSignal, filterMonth = 'MONTH(CURRENT_DATE())') {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard/dataMetrics?filterMonth=${filterMonth}`,  { signal }
    );

    return res.data.data;
  } catch(error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}

export async function getDataGraphics (partnerId, signal?: AbortSignal,filterMonth = 'MONTH(CURRENT_DATE())') {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard?filterMonth=${filterMonth}`,  { signal }
    );

    return res.data.data;
  } catch(error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}

export async function getEnableSelectData (partnerId, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard/selectDataOptions`,  { signal }
    );
    
    return res.data.data;
  } catch(error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}