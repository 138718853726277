import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  height: fit-content;

  .close-button {
    display: none;
  }

  #mobile-header {
    display: none;
  }

  #side-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0 10px 0;
    min-height: 100vh;
    height: 100%;
    background: ${theme.colors.primary};
    min-width: 290px;

    .partners-select {
      width: 100%;
      align-items: start;
      display: flex;
      flex-direction: column;
      z-index: 3000;
      .item-text {
        margin-bottom: 5px;
      }
      .ant-select-selection-item {
        font-weight: bold;
      }
    }
   
  }


  .main-logo {
    flex: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    img {
      width: 110px;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;

    .close-button {
      display: block;
    }

    #mobile-header {
      background-color: ${theme.colors.primary};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 999;

      span {
        svg {
          font-size: ${theme.text.size.xl};
          color: ${theme.colors.secondary};
        }
      }
    }
    .main-logo {

display: flex;
justify-content: space-between;
align-items: center;
/* padding: 0 20px; */

img {
  width: 25%;
}
}
    #side-menu {
      display: none;
      flex-direction: column;
      padding: 30px 0;
      min-height: 100vh;
      background: ${theme.colors.primary};
      overflow-y: auto;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1000;

      .close-button {
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: flex-end;

        span {
          svg {
            font-size: 20px;
          }
        }
      }
      .partners-select {
        display: block;
      }
      .main-logo {
      justify-content: center;

      img {
        width: 25%;
      }
}
    }

    
  }
  .rc-virtual-list-holder-inner{
      z-index:9999 !important;
    }
`;

export const NavBar = styled.nav`
  flex: 1;
  width: 100%;
  

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0px 0px;

    .divisor {
      margin: 15px auto;
      width: 60%;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      cursor: pointer;
      padding: 0;
      width: 100%;
      padding: 10px 20px;
      margin: 0;
      font-size: 14px;
      padding-left: 30px;
      transition: 0.3s;

      :hover {
        transition: 0.3s;
        background-color: ${theme.colors.blue};
        border-right: 5px solid ${theme.colors.light};
        font-size: 16px;
      }

      .item-icon {
        margin-right: 10px;
      }
    }

    li.item-sub-menu {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;
      div.sub-menu-info {
        display: flex;
      }
      div.sub-items {
        transform: scale(1, 0);
        transition: 0.3s;
        height: 0;
        width: 100%;
        
        &.show {
          display: block !important;
          transition: 0.3s;
          transform: scale(1);
          height: 80px;
        }
        
        li{
          :hover {
            background: ${theme.colors.primary};
          }
        }
      }
    .no-hover {
      background-color: transparent !important;
      border: none !important;
      transition: none !important;
      font-size: 14px !important;
    }
  }
  }
  @media screen and (max-width: 900px) {
    ul {
      height: 100%;
      li {
        justify-content: center;
        transition: 0.3s;

        :hover {
          transition: 0.3s;
          background-color: ${theme.colors.blue};
          border-right: none;
        }
        &.item-sub-menu {
          align-items: center;
          }
      }
    }
  }
`;

export const Footer = styled.nav`
  /* border-top: 1px solid ${theme.colors.lightGrey}; */
  padding-bottom: 20px;
  position: relative;
  bottom: 0;
  height: fit-content;
  flex: 0 auto;
  width: 100%;
  margin-top: 10px;

  #user-profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .profile-name {
    margin-top: 10px;
    font-weight: bold;
  }

  img {
    width: 30px;
    height: 30px;
  }

  .bottom {
    cursor: pointer;
    font-weight: bold;
    width: 100%;
  }

  .item {
    transition: 0.3s;
    margin-bottom: 15px;
    padding: 5px;
  }

  .telegram-group {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    span {
      color: ${theme.colors.white};
    }
  }

  @media screen and (max-width: 900px) {
    height: 100%;
  }
`;

// @media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
// @media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
// @media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
// @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
