import * as iquiteiSvc from './iquiteiApi.service';

interface IGetHourInfo {
  startDate?: string;
  endDate?: string;
  signal?: AbortSignal;
};

export async function getHourInfo(partnerId: number, config: IGetHourInfo) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/hour?start_date=${config.startDate || ''}&end_date=${config.endDate || ''}`,
      { signal: config.signal },
    );
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as informações por hora';
    throw err;
  }
}

export async function listByShipment(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${shipmentId}/data/hour`, { signal }
    );
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar as informações por hora';
    throw err;
  }
}
