import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function FinanceChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={150} height={40} data={data}>
        <Tooltip />
        <XAxis dataKey="day" />
        <XAxis
          dataKey="day"
          axisLine={false}
          tickLine={false}
          interval={0}
          height={1}
          scale="band"
          xAxisId="quarter"
        />
        <YAxis dataKey="uv" />
        <Bar dataKey="uv" fill="#617CF9" radius={[5, 5, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}
