const theme = {
  colors: {
    primary: '#260F63',
    secondary: '#E0E1DD',
    tertiary: '#32936F',
    lightGrey: '#D9D9D9',
    disabled: '#f1f2f1',
    input_focus: '#93d1f8ac',
    grey: '#9C9C9C',
    grey_300: '#C3C3C3', 
    table: '#C4BFD4',
    light: '#FAFAFA',
    dark: '#303030',
    red: '#B23A3A',
    blue: '#617CF9',
    blue_light: '#1890FF',
    white: '#FCFCFC',
    healthy: '#248104',
    partial: '#EFBA00',
    issued: '#BC0505',
    danger: '#FF426C',
    warning: '#FFC658',
    success: '#00E48D',
    loading: {
      background: '#0d1a29cc',
      icon: '#E0E1DD',
    },
  },
  text: {
    size: {
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '20px',
      xl: '25px',
      xxl: '35px',
    },
  },
  border: {
    radius: '5px',
    color: '#c7c7c7',
  },
  flexGrid: {
    gap: '15px',
  },
  boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
};

export default theme;
